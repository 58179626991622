import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import defaultLogo from "../assets/logo.png";
import instance from "../services/apiServices/Api";
import { errorMessage } from '../helpers/Message';

const defaultTitle = "Picha Stock";

const JSONLD = ({ isProduct, productData }) => (
    <script type="application/ld+json">
        {isProduct ? `
          {
              "@context": "http://schema.org",
              "@type": "Product",
              "name": "${productData?.title || 'Picha Stock Image'}",
              "image": "${productData?.image || 'https://www.pichastock.com/logo.png'}",
              "description": "${productData?.description || 'High quality stock image from Picha Stock'}",
              "brand": {
                  "@type": "Brand",
                  "name": "Picha Stock"
              },
              "offers": {
                  "@type": "Offer",
                  "url": "${productData?.url || 'https://www.pichastock.com'}",
                  "priceCurrency": "USD",
                  "price": "${productData?.price || '0'}",
                  "availability": "https://schema.org/InStock"
              },
              "creator": {
                  "@type": "Person",
                  "name": "${productData?.contributor?.display_name || 'Picha Stock Contributor'}"
              }
          }
        ` : `
          {
              "@context": "http://schema.org",
              "@type": "Organization",
              "url": "https://www.pichastock.com",
              "name": "Picha Stock",
              "logo": "https://www.pichastock.com/logo.png",
              "contactPoint": {
                  "@type": "ContactPoint",
                  "contactType": "Customer Service",
                  "telephone": "+123456789",
                  "contactOption": "TollFree",
                  "areaServed": "US",
                  "availableLanguage": ["English"]
              },
              "sameAs": [
                  "https://www.facebook.com/pichastock",
                  "https://twitter.com/pichastock",
                  "https://www.instagram.com/pichastock"
              ]
          }
        `}
    </script>
);

const SEO = ({ title, description, logo, canonical, isProduct, productData }) => {
    const [homeContent, setHomeContent] = useState({});

    useEffect(() => {
        const controller = new AbortController();
        getHomepageContent(controller);
        return () => controller.abort();
    }, []);

    const getHomepageContent = async (controller) => {
        await instance
            .get("homepage-content", { signal: controller.signal })
            .then((res) => {
                setHomeContent(res.data.data.homepageContent);
            })
            .catch((error) => {
                errorMessage("Error!", error?.response?.data?.message);
            });
    };

    return (
        <Helmet>
            <title>{title ? title : defaultTitle}</title>
            <meta name="description" content={description ? description : homeContent?.google_search_description} />
            <meta name="title" content={title ? title : defaultTitle} />
            
            {/* Open Graph / Facebook */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content={canonical ? canonical : 'https://www.pichastock.com'} />
            <meta property="og:title" content={title ? title : defaultTitle} />
            <meta property="og:description" content={description ? description : homeContent?.google_search_description} />
            <meta property="og:image" content={logo ? logo : defaultLogo} />
            
            {/* Twitter */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:url" content={canonical ? canonical : 'https://www.pichastock.com'} />
            <meta name="twitter:title" content={title ? title : defaultTitle} />
            <meta name="twitter:description" content={description ? description : homeContent?.google_search_description} />
            <meta name="twitter:image" content={logo ? logo : defaultLogo} />
            
            {/* Canonical Tag */}
            <link rel="canonical" href={canonical ? canonical : 'https://www.pichastock.com'} />
            
            {/* Additional Meta Tags */}
            <meta name="robots" content="index, follow" />
            <meta name="googlebot" content="index, follow" />
            <meta property="og:site_name" content="Picha Stock" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            
            {/* Prefetch Links */}
            <link rel="prefetch" href="/contact" />
            <link rel="prefetch" href="/services" />
            
            {/* Favicon */}
            <link rel="icon" href="/path-to-favicon.ico" />
            
            <JSONLD isProduct={isProduct} productData={productData} />
        </Helmet>
    );
};

export default SEO;